import React from 'react';
import styles from './pro-exclusive-banner.module.scss';
import { Button, ProLogo } from '@whoop/web-components';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function ProExclusiveBanner(): JSX.Element {
  const { t } = useTranslation('whoopPro');

  return (
    <section
      className={styles.container}
      data-impress='Viewed Pro Exclusive Card'
    >
      <ProLogo height={80} width={80} className={styles.proLogoDesktop} />
      <ProLogo height={60} width={60} className={styles.proLogoMobile} />
      <div className={styles.titleContainer}>
        <h2 className={styles.subtitle}>{t('newExclusiveBands')}</h2>
      </div>
      <Button
        label={t('shopNow')}
        variant='primary'
        size='large'
        style={{
          '--color-primary': 'var(--whoop-color-pro-gold)',
          '--color-primary-contrast': 'var(--whoop-color-black)',
          fontWeight: 'bold',
        }}
        data-event='Click Pro Exclusive Show Now'
        onClick={() => navigate('/products/whoop-pro-exclusive/')}
      />
    </section>
  );
}
