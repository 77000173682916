/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
import React, { useRef } from 'react';
import '@whoop/web-components/dist/index.css';
import styles from './homepage.module.scss';
import { classes } from '../../utils';
import { useHasProBenefits } from '../../redux/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FullProduct, useAllProducts } from '../../hooks/useAllProducts';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useHomepageTiles from '../../hooks/useHomepageTiles';
import { Button, Optional, Slideshow } from '@whoop/web-components';
import ProductCardWrapper from '../ProductCardWrapper';
import CollectionList from './CollectionList';
import ProExclusiveBanner from '../ProExclusiveBanner';
import {
  Collection,
  useProductServiceCollection,
} from '../../utils/product-service';
import ProductGrid from '../ProductGrid';
import {
  CollectionRedesignVariant,
  useCollectionRedesign,
} from '../../hooks/useCollectionExperiment';
import { trackWhoopAnalyticsEvent } from '../../utils/analytics';

const isVideo = (src: string) => src?.includes('.mp4');

function Media({ src }: { src: string }) {
  if (!src) {
    return <></>;
  }

  // Video
  if (isVideo(src)) {
    return (
      <video autoPlay loop playsInline muted>
        <source src={src} type='video/mp4' />
      </video>
    );
  }

  // image
  return <img src={src} />;
}

export default function Homepage(): JSX.Element {
  const { t } = useTranslation('homepage');
  const hasProBenefits = useHasProBenefits();
  const bestSellerProducts = useAllProducts('bestseller')?.filter(
    (item) => item.handle,
  );
  const newProducts = useAllProducts('new')?.filter((item) => item.handle);
  const bestSellerCollection = useProductServiceCollection('best-sellers');
  const newCollection = useProductServiceCollection('new');
  const collectionRedesignFeature = useCollectionRedesign();
  const collectionRedesignFeatureOn =
    collectionRedesignFeature &&
    collectionRedesignFeature !== CollectionRedesignVariant.control;

  // sort the collection products to match the shopify list
  const sortCollections = (
    product: FullProduct[] | undefined,
    collection: Optional<Collection>,
  ) => {
    product?.sort((a, b) => {
      if (collection?.products && a.handle && b.handle) {
        return (
          collection?.products.indexOf(a.handle) -
          collection?.products.indexOf(b.handle)
        );
      }
      return 0;
    });
  };

  const newCarouselEvent = {
    onChange: () =>
      trackWhoopAnalyticsEvent('Scrolled the carousel', {
        source: 'New carousel',
      }),
  };

  const bestSellerCarouselEvent = {
    onChange: () =>
      trackWhoopAnalyticsEvent('Scrolled the carousel', {
        source: 'Best seller carousel',
      }),
  };

  sortCollections(newProducts, newCollection);
  sortCollections(bestSellerProducts, bestSellerCollection);

  const { main, secondary_top, secondary_bottom } = useHomepageTiles();

  const $button1 = useRef<HTMLButtonElement>(null);
  const $button2 = useRef<HTMLButtonElement>(null);
  const $button3 = useRef<HTMLButtonElement>(null);

  return (
    <div>
      <div className={styles.homepageHeroTiles}>
        <section>
          <div className={styles.homepageHeroTilesContainer}>
            <div className={styles.tileContainer}>
              <Link
                aria-label={main?.title?.toUpperCase()}
                to={main?.link}
                className={styles.tile}
                target='_blank'
                rel='noopener noreferrer'
                onMouseEnter={() => {
                  $button1.current?.focus({ preventScroll: true });
                }}
                onMouseLeave={() => {
                  $button1.current?.blur();
                }}
                data-event='Clicked Homepage Hero'
                data-event-source='hero_image'
                data-event-landing_page={main?.link}
                data-event-hero-position='main_left'
                data-impress='Viewed Homepage Hero'
              >
                <div
                  className={classes(
                    styles.imageContainer,
                    styles.imageContrast,
                    isVideo(main?.image) && styles.highContrast,
                  )}
                >
                  {!isVideo(main?.image) && main?.staticImage ? (
                    <GatsbyImage
                      image={getImage(main.staticImage)!}
                      alt={main?.title}
                      className={styles.staticImage}
                    />
                  ) : (
                    <Media src={main?.image} />
                  )}
                </div>
                <div className={styles.tileContent}>
                  <header>
                    <h3>{main?.sub_title}</h3>
                    <h2>{main?.title}</h2>
                    <Button
                      label={t('shopNow')}
                      size='large'
                      variant='primary'
                      ref={$button1}
                      data-event='Clicked Homepage Hero'
                      data-event-source='shop_now_button'
                      data-event-landing_page={main?.link}
                      data-event-type={isVideo(main?.image) ? 'video' : 'image'}
                    />
                  </header>
                </div>
              </Link>
              <Link
                to={secondary_top.link}
                aria-label={secondary_top.title}
                className={styles.tile}
                target='_blank'
                rel='noopener noreferrer'
                onMouseEnter={() => {
                  $button2.current?.focus({ preventScroll: true });
                }}
                onMouseLeave={() => {
                  $button2.current?.blur();
                }}
                data-event='Clicked Homepage Hero'
                data-event-source='hero_image'
                data-event-landing_page={secondary_top.link}
                data-event-type={
                  isVideo(secondary_top.image) ? 'video' : 'image'
                }
                data-event-hero-position='top_right'
                data-impress='Viewed Homepage Hero'
              >
                <div
                  className={classes(
                    styles.imageContainer,
                    styles.imageContrast,
                    isVideo(secondary_top.image) && styles.highContrast,
                  )}
                >
                  {!isVideo(secondary_top?.image) &&
                  secondary_top?.staticImage ? (
                    <GatsbyImage
                      image={getImage(secondary_top.staticImage)!}
                      alt={secondary_top?.title}
                      className={styles.staticImage}
                    />
                  ) : (
                    <Media src={secondary_top?.image} />
                  )}
                </div>
                <div className={styles.tileContent}>
                  <header>
                    <h2>{secondary_top.title}</h2>

                    <div className={styles.buttonGroup}>
                      <Button
                        label={secondary_top.sub_title}
                        variant='link'
                        ref={$button2}
                      />
                    </div>
                  </header>
                </div>
              </Link>
              <Link
                to={secondary_bottom?.link}
                aria-label={secondary_bottom?.title}
                className={styles.tile}
                target='_blank'
                rel='noopener noreferrer'
                onMouseEnter={() => {
                  $button3.current?.focus({ preventScroll: true });
                }}
                onMouseLeave={() => {
                  $button3.current?.blur();
                }}
                data-event='Clicked Homepage Hero'
                data-event-source='hero_image'
                data-event-landing_page={secondary_bottom?.link}
                data-event-type={
                  isVideo(secondary_bottom?.image) ? 'video' : 'image'
                }
                data-event-hero-position='bottom_right'
                data-impress='Viewed Homepage Hero'
              >
                <div
                  className={classes(
                    styles.imageContainer,
                    styles.imageContrast,
                    isVideo(secondary_bottom?.image) && styles.highContrast,
                  )}
                >
                  {!isVideo(secondary_bottom?.image) &&
                  secondary_bottom?.staticImage ? (
                    <GatsbyImage
                      image={getImage(secondary_bottom.staticImage)!}
                      alt={secondary_bottom?.title}
                      className={styles.staticImage}
                    />
                  ) : (
                    <Media src={secondary_bottom?.image} />
                  )}
                </div>
                <div className={styles.tileContent}>
                  <header>
                    <h2>{secondary_bottom?.title}</h2>

                    <div className={styles.buttonGroup}>
                      <Button
                        label={secondary_bottom?.sub_title}
                        variant='link'
                        ref={$button3}
                      />
                    </div>
                  </header>
                </div>
              </Link>
            </div>
          </div>

          <span id='section-slideshow-end' className='Anchor'></span>
        </section>
      </div>
      {newProducts &&
        newProducts.length > 0 &&
        collectionRedesignFeatureOn &&
        collectionRedesignFeature === CollectionRedesignVariant.new && (
          // when the test variant is new
          <div className={styles.productCardCollection}>
            <section>
              <h2 className={styles.heading}>{t('new')}</h2>
              {/* when products length is <= 4 */}
              {newProducts && newProducts?.length <= 4 ? (
                <div className={styles.productCollection}>
                  {newProducts.map((item, index) => (
                    <div
                      key={item.handle}
                      data-event-item-position={index + 1}
                      data-event-item={item.title}
                      data-impress='Viewed New Seller'
                    >
                      <ProductCardWrapper item={item} userLocation='Homepage' />
                    </div>
                  ))}
                </div>
              ) : (
                // when product length is > 4
                <Slideshow
                  pageDots={true}
                  multiImage={true}
                  prevNextButtons={true}
                  wrapAround={true}
                  cellAlignForWrapAround={true}
                  className={styles.productCards}
                  {...newCarouselEvent}
                >
                  {newProducts?.slice(0, 10).map((item, index) => (
                    <div
                      key={item.handle}
                      data-event-item-position={index + 1}
                      data-event-item={item.title}
                      data-impress='Viewed New'
                    >
                      <ProductCardWrapper
                        className={styles.productCardWrappers}
                        item={item}
                        carouselLocation='New carousel'
                        userLocation='Homepage'
                      />
                    </div>
                  ))}
                </Slideshow>
              )}
            </section>
          </div>
        )}
      {!hasProBenefits && <div className={styles.separator} />}
      {hasProBenefits && <ProExclusiveBanner />}
      <CollectionList />
      {bestSellerProducts && bestSellerProducts.length > 0 && (
        <div className={styles.productCardCollection}>
          <section>
            <h2 className={styles.heading}>{t('bestSellers')}</h2>
            {collectionRedesignFeatureOn ? (
              // when the test variant is not control
              <>
                {/* when product length is <= 4 */}
                {bestSellerProducts && bestSellerProducts?.length <= 4 ? (
                  <div className={styles.productCollection}>
                    {bestSellerProducts.map((item, index) => (
                      <div
                        key={item.handle}
                        data-event-item-position={index + 1}
                        data-event-item={item.title}
                        data-impress='Viewed Best Seller'
                      >
                        <ProductCardWrapper
                          item={item}
                          userLocation='Homepage'
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  // when product length is > 4
                  <Slideshow
                    pageDots={true}
                    multiImage={true}
                    prevNextButtons={true}
                    wrapAround={true}
                    cellAlignForWrapAround={true}
                    className={styles.productCards}
                    {...bestSellerCarouselEvent}
                  >
                    {bestSellerProducts?.map((item, index) => (
                      <div
                        key={item.handle}
                        data-event-item-position={index + 1}
                        data-event-item={item.title}
                        data-impress='Viewed Best Seller'
                      >
                        <ProductCardWrapper
                          className={styles.productCardWrappers}
                          item={item}
                          carouselLocation='Best-seller carousel'
                          userLocation='Homepage'
                        />
                      </div>
                    ))}
                  </Slideshow>
                )}
              </>
            ) : (
              // when the test vairant is control
              <ProductGrid>
                {bestSellerProducts?.slice(0, 4).map((item, index) => (
                  <div
                    key={item.handle}
                    data-event-item-position={index + 1}
                    data-event-item={item.title}
                    data-impress='Viewed Best Seller'
                  >
                    <ProductCardWrapper item={item} userLocation='Homepage' />
                  </div>
                ))}
              </ProductGrid>
            )}
          </section>
        </div>
      )}
    </div>
  );
}
